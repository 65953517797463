import React from 'react';
import { Grid, Segment, Header, Message, Divider, Icon, Label, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default (props) => {
    const { from } = props.location.state || { from: { pathname: props.location.pathname !== '404' ?  props.location.pathname : '/' } };
    const { history } = props;
    return (
        <Grid centered columns={2}>
            <Grid.Row>
                <Grid.Column width={3}>
                    <Segment circular style={{ width: 175, height: 175 }} color="red" inverted compact size="massive">
                        <Header>404
                            <Header.Subheader style={{ color: "white" }}>
                                Not found
                            </Header.Subheader>
                        </Header>
                    </Segment>
                </Grid.Column>
                <Grid.Column width={13}>
                    <Message negative size="huge">
                        <Header as='h1' icon>
                            <Icon name="warning sign"/>
                            This is the infamous 404 page
                            <Header.Subheader>
                                Something went wrong: you shouldn't be here!
                            </Header.Subheader>
                        </Header>
                        <Divider horizontal/>
                        {from.pathname !== '/' &&
                        <Segment size="huge" color="red">
                            Just to let you know, you reached this page because <em>the requested url</em>
                            <Divider horizontal/>
                            <div style={{ textAlign: "center "}}>
                                <Label size="massive" color="red"><code>{from.pathname}</code></Label>
                            </div>
                            <Divider horizontal/>
                            <em>does not exist</em>, <em>is not correct</em>, or <em>some other kind of error occurred</em>.
                        </Segment>}
                        <Divider horizontal/>
                        <p>Anyway, now it is too late to regret: to reach a safe state, either <Button onClick={() => { history.goBack(); }}><Icon name="left arrow"/> go back</Button> with the browser button
                            or proceed to the <Link to="/"><Icon name="home"/> Home Page</Link>.</p>
                        <p>If you think that there is no good reason to have come to this page, please <a href="
                            https://github.com/liuq/OptHub/issues/new">report this error</a>.</p>
                    </Message>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}