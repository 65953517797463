import React from 'react';
import PropTypes from 'prop-types'
import api from '../utils/api';
import { Search, Label } from 'semantic-ui-react';
import autobind from 'class-autobind';
import { withRouter } from 'react-router';
import { debounce } from 'lodash';

// TODO: debug search (children keys errors, unknown property path in div tag)
//       but first check if they are due to semantic-ui-react prbolems

const resultRenderer = (props) => {
    return (
        <Label as="span" content={props.name} />
    );
};

const categoryRenderer = (props) =>
    (<Label as="span" content={props.name} />);

categoryRenderer.propTypes = {
    name: PropTypes.string
};

resultRenderer.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    path: PropTypes.string
};

class MySearch extends React.Component {
    state = { searching: false };

    constructor(props) {
        super(props);
        autobind(this);
        this.search = debounce(this.search, 500);
    }


    static getDerivedStateFromProps(props, state) {
       return MySearch.resetComponent();
    }

    static resetComponent() {
        return { isLoading: false, results: [], value: "" }
    }

    handleResultSelect(e, result) {
        this.props.history.push(result.path);
        this.setState(MySearch.resetComponent());
    }

    handleSearchChange(e, value) {
        this.setState({ isLoading: true, value });
        this.search(value);
    }

    search(value) {
        api.search(value).then((results) => {
            this.setState({ isLoading: false, results: results });
        }).catch(() => {
            this.setState({ isLoading: false , results: [] })
        });
    }

    render() {
        const { isLoading, value, results } = this.state;
        return (
            <Search category
                    categoryRenderer={categoryRenderer}
                    resultRenderer={resultRenderer}
                    loading={isLoading}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={this.handleSearchChange}
                    results={results}
                    minCharacters={3}
                    value={value} />
        );
    }
}

export default withRouter(MySearch);
