import 'semantic-ui-css/semantic.css';
import './styles/custom.css';
import 'react-notifications/lib/notifications.css';

import React from 'react';
import ReactDOM from 'react-dom';

import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { Provider } from 'react-redux';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak, { eventManager } from './utils/keycloak';

import { NotificationContainer } from 'react-notifications';
import store, { browserHistory } from './utils/store';

import { Container, Grid, Divider,  Dimmer, Loader } from 'semantic-ui-react';
import Welcome from './components/welcome';
import Problem from './containers/problem';
import NavBar from './components/navbar';
import ProblemTree from './components/problem-tree';
import FourOFour from './components/errors/four-o-four';
import Instance from './containers/instance';

// TODO: completely review all messages

const loadingKeycloak = (
<Dimmer active inverted>
    <Loader />
</Dimmer>
);

ReactDOM.render(
    <React.StrictMode>       
        <ReactKeycloakProvider authClient={keycloak} onEvent={eventManager} LoadingComponent={loadingKeycloak}>
            <Provider store={store}>
                <Container>
                    <ConnectedRouter history={browserHistory}>
                        <NotificationContainer/>
                        <NavBar />
                        <Divider horizontal/>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Switch>
                                        <Route exact path="/" component={Welcome}/>
                                        <Route exact path="/404" component={FourOFour}/>
                                        <Route path="/tree" component={ProblemTree}/>
                                        <Route path="/problem/:path+" component={Problem}/>
                                        <Route path="/instance/:path*/:id" component={Instance}/>
                                        <Route component={FourOFour}/>
                                    </Switch>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </ConnectedRouter>
                </Container>
            </Provider>
        </ReactKeycloakProvider>       
    </React.StrictMode>,
  document.getElementById('root')
);
