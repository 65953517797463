import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'class-autobind';
import { Table, Grid } from 'semantic-ui-react';
import { chunk, isArray, isObject, round, isInteger, isNumber, truncate } from 'lodash';

class FeaturesTable extends React.Component {
    static propTypes = {
        features: PropTypes.array.isRequired,
        totalCost: PropTypes.number,
        min: PropTypes.number,
        max_columns: PropTypes.number
    };


    static defaultProps = {
        min: 5,
        max_columns: 3
    };

    constructor(props) {
        super(props);
        autobind(this);
    }

    fillRow(name, value) {
        if (!isArray(value) && !isObject(value))
            return <>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>{isNumber(value) & !isInteger(value) ? round(value, 2) : value}</Table.Cell>
            </>;
        else
            return <>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>{truncate(JSON.stringify(value), 20)}</Table.Cell>
            </>;
    }

    fillTable(f, key) {
        return (
            <Table definition key={key} stackable columns={2}>
                <Table.Body>
                    {f.map((item, index) => (
                        <Table.Row key={index}>
                            {this.fillRow(item.name, item.value)}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>  
        )
    }

    render() {
        const { features, min, max_columns, totalCost } = this.props;
        if (!features)
            return null;
        if (features.length < min)
            return this.fillTable(features, 0);
        const columns = Math.min(Math.ceil(features.length / min), max_columns);
        const tables = chunk(features, Math.ceil(features.length / columns)).map((set, index) => this.fillTable(set, index));
        return (
            <Grid columns={columns} divided stackable>
                <Grid.Row>
                    {tables.map((t, index) => (<Grid.Column key={index}>{t}</Grid.Column>))}
                </Grid.Row>
                {totalCost && 
                    <Grid.Row>
                        <strong>Total cost:</strong> {totalCost}
                    </Grid.Row>
                }  
            </Grid>
        );
    }
}

export default FeaturesTable;

