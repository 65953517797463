import React from 'react';
import { connect } from 'react-redux';
import { Loader, Icon, Image, Dropdown } from 'semantic-ui-react';
import keycloak from '../utils/keycloak';
import md5 from 'md5';
import { requireLogout } from '../ducks/login';

const LoginButton = ({ initialized, authenticated, userinfo, dispatch }) => {
    if (!initialized && !authenticated)
         return <><Loader size='mini' active inline />Connecting</>;
    else if (!authenticated)
    {
        return <a href={keycloak.createLoginUrl()}><Icon name="sign in"/> Sign in</a>;        
    }
    else {
        const gravatar = md5(userinfo.email);
        const trigger = <><Image avatar src={`https://www.gravatar.com/avatar/${gravatar}`}/> {userinfo.name}</>;
        const options = [
            { key: 'user', text: 'Profile', icon: 'user', value: 'profile' },
            //{ key: 'settings', text: 'Settings', icon: 'settings' },
            { key: 'logout', text: 'Sign out', icon: 'sign out', value: 'logout' },
        ];
        const handleChange = (event, { value }) => {   
            switch (value) {
                case 'logout': dispatch(requireLogout());
                break;
                case 'profile': keycloak.accountManagement();
                break;
            }
        };
        return <Dropdown trigger={trigger} options={options} pointing='top left' onChange={handleChange}/>
    }
};

export default connect(state => ({ ...state.login }))(LoginButton);

