import { NotificationManager } from 'react-notifications';

export default [
    {
        catch: 'LOGIN_FULFILLED',
        dispatch: () => NotificationManager.success('Login successful'),
    },
    {
        catch: ['SAVE_PROBLEM_FULFILLED'],
        dispatch: () => NotificationManager.success('Data successfully saved')
    },
    {
        catch: ['SAVE_PROBLEM_REJECTED'],
        dispatch: () => NotificationManager.success('Cannot save data')
    },
    {
        catch: 'LOGOUT',
        dispatch: () => NotificationManager.info('Logged out')
    }
];