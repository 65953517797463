import Keycloak from 'keycloak-js';
import { initialized, loggedIn } from '../ducks/login';
import api from './api';
import store from './store';
import config from '../config/config';

const keycloak = new Keycloak({
    url: config['keycloakMountpoint'],
    realm: "opthub",
    clientId: "opthub-spa",
    clientSecret: config['keycloakClientSecret']
});

const eventManager = (event, error) => {
    if (process.env.NODE_ENV != 'production')
        console.log(event);
    switch (event) {
        case 'onReady': store.dispatch(initialized()); 
        break;
        case 'onAuthSuccess': 
            api.get('notify-user-signin', {}, {}, true).then(() => store.dispatch(loggedIn(keycloak.authenticated, keycloak.subject, keycloak.tokenParsed))).catch(() => console.error('Not able to notify user signin'));
        break;
        case 'onAuthRefreshSuccess': store.dispatch(loggedIn(keycloak.authenticated, keycloak.subject, keycloak.tokenParsed));
        break;
    }
};

export { keycloak as default, eventManager };