import React from 'react';
import ReactDOM from 'react-dom';
import autobind from 'class-autobind';
import PropTypes from 'prop-types';
import {  Menu, Form, Icon, Modal, Button, Rail, Grid } from 'semantic-ui-react';
import $ from 'jquery';
import { diffWordsWithSpace } from 'diff';
import cx from 'classnames';
import CloseOnEscape from 'react-close-on-escape';

const propMapper = ({ onSave, value, ...otherProps }) => ({ ...otherProps });

const Editable = (EditComponent) => class extends React.Component {
    static propTypes = {
        editable: PropTypes.bool.isRequired,
        onSave: PropTypes.func
    };

    constructor(props) {
        super(props);
        autobind(this);
        this.state = { content: props.value, newContent: null, edit: false, dialog: false, diff: [] };
        this.editMenu = React.createRef();
    }

    handleSave(e) {
        e.preventDefault();
//        const newContent = $(this.editObject.current).find("input,textarea,select").val();
        const newContent = this.state.newContent || "";
        const diff = diffWordsWithSpace(this.state.content || "", newContent || "", { newlineIsToken: false });
        const changed = diff.some(e => e.added || e.removed);
        if (changed && this.props.onSave)
            this.props.onSave(newContent);
        this._toggleEdit();
        if (changed)
            this.setState({ content: newContent });
    }

    handleCancel(e) {
        e.preventDefault();
        this.setState({ value: this.props.value, dialog: false });
        this._toggleEdit();
    }

    tryCancel(e) {
        if (this.state.edit && !this._checkDiffs())
            this._toggleEdit();
        if (e)
            e.preventDefault();
    }

    _toggleEdit() {
        this.setState({ edit: !this.state.edit });
    }

    _checkDiffs() {
//        const newContent = $(this.editObject.current).find("input,textarea,select").val();
        const newContent = this.state.newContent || "";
        const diff = diffWordsWithSpace(this.state.content || "", newContent || "", { newlineIsToken: false });
        const changed = diff.some(e => e.added || e.removed);
        this.setState({ dialog: changed, diff });
        return changed;
    }

    static getDerivedStateFromProps(props, state) {
        return { content: props.value };
    }

    onChange(e) {
        console.log(e);
        if (e && e.target)
            this.setState({ newContent: e.target.value });
        else if (e)
            this.setState({ newContent: e });
    }

    render() {
        if (this.props.editable) {
            // attach onchange to current element
            const editElement = React.cloneElement(EditComponent, { onChange: this.onChange })
            const edit = (
                <Form as="div">
                    <CloseOnEscape onEscape={this.tryCancel}>
                       {editElement}
                    </CloseOnEscape>
                    <Button.Group>
                        <Button positive onClick={this.handleSave}><Icon name="save"/> Save</Button>
                        <Button.Or />
                        <Button negative onClick={this.tryCancel}><Icon name="cancel"/> Cancel</Button>
                    </Button.Group>
                </Form>
            );
            const dialog = (
                <Modal open={this.state.dialog}>
                    <Modal.Header>You are about to discard your updates</Modal.Header>
                    <Modal.Content>
                        The changes are highlighted:
                        <div>
                            {this.state.diff.map((part, index) => (
                                <span className={cx('diff-highlight', {added: part.added, removed: part.removed})}
                                      key={index}>{part.value}</span>
                            ))}
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button.Group>
                            <Button positive onClick={this.handleSave} icon="save" labelPosition="left" content="Save"/>
                            <Button.Or />
                            <Button negative onClick={this.handleCancel} icon="cancel" labelPosition="right" content="Ignore"/>
                        </Button.Group>
                    </Modal.Actions>
                </Modal>
            );
            let editButtons = null;
            if (!this.state.edit) {
                editButtons = (
                    <div>
                        <Menu.Item icon="edit" onClick={this._toggleEdit}/>
                    </div>);
            } else {
                editButtons = (
                    <div>
                        <Menu.Item icon="save" onClick={this.handleSave} className="positive"/>
                        <Menu.Item icon="cancel" onClick={this.tryCancel} className="negative"/>
                    </div>);
            }
            return (
                <Grid centered columns={1}>
                    <Grid.Column>
                        {this.state.edit ? edit : this.props.children}
                        <Rail attached position="right">
                            {dialog}
                            <Menu text compact ref={this.editMenu}>
                                {editButtons}
                            </Menu>
                        </Rail>
                    </Grid.Column>
                </Grid>
            );
        }
        else
            return (
                <Grid centered columns={1}>
                    <Grid.Column>
                        {this.props.children}
                    </Grid.Column>
                </Grid>
            );
    }
}

export default Editable;