import { createStore, applyMiddleware } from 'redux'
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import reducer from '../ducks/index';
import promiseMiddleware from 'redux-promise-middleware';
import logger from 'redux-logger';
import notify from './notify-middleware';

const browserHistory = createBrowserHistory();

const middleware = [ routerMiddleware(browserHistory), promiseMiddleware(), notify ];
if (process.env.NODE_ENV !== 'production') {
    middleware.push(logger);
} else {
    middleware.push(logger);
}

const store = createStore(reducer(browserHistory), applyMiddleware(...middleware));

export { store as default, browserHistory };