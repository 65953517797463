import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Menu, Icon } from 'semantic-ui-react';
import Search from './search';
import LoginButton from './login';

export default (props) => {
    return (
        <Container>
            <Menu as="nav" className="inverted navbar">
                <Menu.Item as={Link} to="/">
                    <Icon className="icon-opthub" />&nbsp; OptimizationHub
                </Menu.Item>
                <Menu.Item as={Link} to="/tree">
                    Problems &nbsp;<Icon name="sitemap" />
                </Menu.Item>
                <div className="right menu item">
                    <Search />
                </div>
                <div className="right menu item">
                    <LoginButton />
                </div>
            </Menu>
            { /* TODO: add responsive version, do not know how to do yet */}
        </Container>
    );
};