import { handleActions, createAction } from 'redux-actions';
import api from '../utils/api';

// initial state

const initialState = { data: {}, loading: false, invalid: false };

// actions

const loadInstance = createAction('LOAD_INSTANCE', id => api.get(`instances/${id}`));
const saveInstance = createAction('SAVE_INSTANCE', instance => api.put(instance._links.self.url, instance));
const saveInstanceField = createAction('SAVE_INSTANCE_FIELD', (instance, field, value) => api.put(instance._links.self.url, { id: instance.id, [field]: value }));

// reducer

const reducer = handleActions({
    'LOAD_INSTANCE_PENDING': (state ,action) => ({...state, loading: true, invalid: false }),
    'LOAD_INSTANCE_FULFILLED': (state, action) => ({ data: action.payload.body.result, loading: false, invalid: false }),
    'LOAD_INSTANCE_REJECTED': (state, action) => ({ data: {}, loading: false, invalid: { code: action.payload.status, message: action.payload.message } }),
    'SAVE_INSTANCE_FIELD_FULFILLED': (state, action) => ({...state, data: action.payload.body.result }),
    'SAVE_INSTANCE_FULFILLED': (state, action) => ({...state, data: action.payload.body.result })
}, initialState);

export { reducer as default, loadInstance, saveInstance, saveInstanceField };

