import React from 'react';
import Dropzone from 'react-dropzone';
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import { Form, Header, Message, Divider, Label, Dropdown, Menu, Segment } from 'semantic-ui-react';
import autobind from 'class-autobind';
import PropTypes from 'prop-types';
import { uniqWith, isEqual, debounce } from 'lodash';
import cx from 'classnames';
import filesize from 'filesize';

const renderTextField = ({ input, label, meta: { touched, error, submitting, validating }, ...custom }) => {
    return (
        <div>
            <Form.Input placeholder={custom.placeholder || label}
                label={label}
                error={touched && !!error}
                disabled={submitting || validating}
                {...input}
                {...custom}
            />
            {touched && error && (<Message error visible>{error}</Message>)}
        </div>
    );
};


const renderTextAreaField = ({ input, label, meta: { touched, error, submitting, validating }, ...custom }) => (
    <div>
        <Form.TextArea placeholder={custom.placeholder || label}
            label={label}
            error={touched && !!error}
            disabled={submitting || validating}
            {...input}
            {...custom}
        />
        {touched && error && (<Message error visible>{error}</Message>)}
    </div>
);

class renderEditorField extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = debounce(this.handleChange, 200);
    }

    handleChange = (value) => this.props.input.onChange(value);

    shouldComponentUpdate(nextProps) {
        /* the input value is kept only as the initial value, then the editor will update itself */
        if (nextProps.input.value !== this.props.input.value)
            return false;
        return nextProps !== this.props;
    }

    render() {
        const { input, label, meta: { touched, error, submitting, validating }, required, ...custom } = this.props;
        return (
            <Form.Field error={touched && !!error} disabled={submitting || validating} required={!!required}>
                <label>{label}</label>
                <SimpleMDE placeholder={(custom && custom.placeholder) || label}
                    name={input.name}
                    value={input.value}
                    onChange={this.handleChange}
                    {...custom}
                />
                {touched && error && (<Message error>{error}</Message>)}
            </Form.Field>
        );
    }
}

const renderDropzoneInputField = ({ input, label, required, meta: { pristine, error, invalid, submitting, validating }, ...custom }) => {
    // TODO: add the option to remove the selected file (when successful), automatically clear invalid files
    // TODO: double-check features to verify that they refer to the same file accepted
    const files = input.value;
    // touched does not work with dropzone, we have to resort to pristine

    return (
        <Form.Field required={!!required} error={!pristine && !!error}>
            <label>{label}</label>
            <Dropzone
                name={input.name}
                disabled={submitting || validating}
                multiple={false}
                //                noDragEventsBubbling={true}
                onDropAccepted={input.onChange}
            >
                {({ getRootProps, getInputProps, isDragActive }) => (
                    <section>
                        <div {...getRootProps({ className: cx('dropzone', { enter: isDragActive, error: !pristine && invalid && !isDragActive }) })}>
                            <input {...getInputProps()} />
                            {files && files[0] ?
                                (<div>
                                    {files[0].name}
                                    <div>{filesize(files[0].size)}</div>
                                </div>) :
                                (<div>{custom.placeholder}</div>)
                            }
                        </div>
                    </section>
                )}
            </Dropzone>
            {!pristine && error ? (
                <Message error visible>
                    <Message.Header>Validation error</Message.Header>
                    <div>
                        {files && Array.isArray(files) && files.map((file, i) => <Label color="red" key={i} icon="file text" content={file.name} />)} is invalid, please select another file
                    </div>
                    <Divider horizontal />
                    <div>
                        <Header as="h4">Reason</Header>
                        {error}
                    </div>
                </Message>
            ) : null}
        </Form.Field>
    );
};

class renderHybridFileTextAreaField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: props.input.value.activeTab || 'file',
            format: props.input.value.format || props.formats[0],
            changed: false,
            textContent: props.input.value.content || ''
        };
        autobind(this);
        this.handleTextChange = debounce(this.handleTextChange, 200);
    }


    handleTabClick = (e, { name }) => this.setState({ activeTab: name });

    handleFormatChange = (e, { value }) => this.setState({ format: value, changed: true });

    handleTextChange = (e, { value }) => this.setState({ textContent: value, changed: true });

    setFile = (e) => {
        const { input } = this.props;
        const prefix = this.props.filePrefix || 'anonymous';
        const { format, activeTab, textContent } = this.state;
        const file = new File([textContent], `${prefix}${format}`, { type: 'application/octet-stream' });
        input.value = { files: [file], activeTab, content: textContent, format };
        input.onBlur(input.value);
        this.setState({ changed: false });
    };

    render() {
        const { input, labelFile, labelText, required, formats, meta: { touched, pristine, invalid, error, submitting, validating },
            placeholderFile, placeholderText, filePrefix, ...custom } = this.props;
        const { activeTab, textContent, format, changed } = this.state;

        const files = input.value, content = textContent;

        const formatSelection = (
            <Form.Group inline required>
                <label htmlFor="format">Format</label>
                {formats.map((item, index) => (<Form.Radio name="format" label={item} value={item} key={index}
                    checked={item === format} readOnly={formats.length === 1}
                    onChange={this.handleFormatChange}
                />))}
            </Form.Group>
        );

        return (
            <div>
                <Menu attached='top' tabular>
                    <Menu.Item name='file' active={activeTab === 'file'} onClick={this.handleTabClick} />
                    <Menu.Item name='text' active={activeTab === 'text'} onClick={this.handleTabClick} />
                </Menu>
                <Segment attached='bottom'>
                    <Form.Field required={!!required} error={touched && !!error}>
                        {activeTab === "file" && (
                            <div>
                                <label htmlFor={input.name}><strong>{labelFile}</strong></label>
                                <Dropzone
                                    name={input.name}
                                    disabled={submitting || validating}
                                    multiple={false}
                                    // noDragEventsBubbling={true}
                                    onDropAccepted={input.onChange}
                                >
                                    {({ getRootProps, getInputProps, isDragActive }) => (
                                        <section>
                                            <div {...getRootProps({ className: cx('dropzone', { enter: isDragActive, error: !pristine && invalid && !isDragActive }) })}>
                                                <input {...getInputProps()} />
                                                {files && files[0] ?
                                                    (<div>
                                                        {files[0].name}
                                                        <div>{filesize(files[0].size)}</div>
                                                    </div>) :
                                                    (<div>{placeholderFile}</div>)
                                                }
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                                {!pristine && error ? (
                                    <Message error visible>
                                        <Message.Header>Validation error</Message.Header>
                                        <div>
                                            {files && Array.isArray(files) && files.map((file, i) => <Label color="red" key={i} icon="file text" content={file.name} />)} is invalid, please select another file
                                        </div>
                                        <Divider horizontal />
                                        <div>
                                            <Header as="h4">Reason</Header>
                                            {error}
                                        </div>
                                    </Message>
                                ) : null}
                            </div>
                        )}
                        {activeTab === "text" && (
                            <div>
                                {formatSelection}
                                <Form.TextArea placeholder={placeholderText || labelText}
                                    label={labelText}
                                    error={touched && !!error}
                                    disabled={submitting || validating}
                                    {...custom}
                                    defaultValue={content}
                                    onChange={this.handleTextChange}
                                />
                                <Form.Button positive type="button" icon="checkmark"
                                    labelPosition="left" content="Confirm" disabled={submitting || validating || !changed}
                                    onClick={this.setFile} />
                            </div>
                        )}
                        {touched && error && (
                            <Message error>
                                <Message.Header>Validation error</Message.Header>
                                <div>
                                    {files && Array.isArray(files) && files.length > 0 && (
                                        <span>
                                            The file you provided is invalid, please select another file or provide another textual content
                                        </span>
                                    )}
                                </div>
                                <Divider horizontal />
                                <div>
                                    <Header as="h4">Reason</Header>
                                    {error}
                                </div>
                            </Message>
                        )}
                    </Form.Field>
                </Segment>
            </div>
        );
    }
}

class renderTagField extends React.Component {
    static propTypes = {
        options: PropTypes.array,
        value: PropTypes.array,
        search: PropTypes.func.isRequired,
        initialOptions: PropTypes.func
    };

    constructor(props) {
        super(props);
        autobind(this);
        this.search = debounce(this.search, 500);
        this.state = {
            loading: false,
            value: props.value || [],
            options: props.options || [],
        };
    }

    componentDidMount() {
        if (this.props.initialOptions && !this.props.options && !this._asyncRequest) {
            this.setState({ loading: true });
            this._asyncRequest = this.props.initialOptions().then(
                results => {
                    this._asyncRequest = null;
                    this.setState({
                        loading: false,
                        options: uniqWith([...results, ...this.state.options], isEqual)
                    });
                }).catch(() => this.setState({ loading: false }));
        }
    }

    componentWillUnmount() {
        if (this._asyncRequest)
            this._asyncRequest.cancel();
    }

    handleAddition(e, { value }) {
        this.setState({
            options: [{ text: value, value }, ...this.state.options],
        })
    }

    handleChange(e, { value }) {
        this.setState({ value });
        this.props.input.onChange(value);
    }

    handleSearchChange(e, value) {
        this.setState({ loading: true });
        this.search(value);
    }

    search(value) {
        this.props.search(value)
            .then((results) => this.setState({
                loading: false,
                options: uniqWith([...results, ...this.state.options], isEqual)
            }))
            .catch(() => this.setState({ loading: false }));
    }

    render() {
        const { input, meta: { touched, error, submitting, validating } } = this.props;

        return (
            <Form.Field>
                <Dropdown
                    options={this.state.options}
                    placeholder={this.props.placeholder || ""}
                    search
                    selection
                    fluid
                    multiple
                    allowAdditions
                    scrolling
                    value={this.state.value}
                    onAddItem={this.handleAddition}
                    onChange={this.handleChange}
                    onSearchChange={this.handleSearchChange}
                    disabled={this.state.loading || submitting || validating}
                    loading={this.state.loading}
                    additionPosition="bottom"
                    name={input.name}
                />
                {touched && error && (<Message error>{error}</Message>)}
            </Form.Field>
        )
    }
}

export { renderTextField, renderDropzoneInputField, renderTextAreaField, renderEditorField, renderTagField, renderHybridFileTextAreaField };