import React, { useState, useContext } from 'react';
import autobind from 'class-autobind';
import { Header, Modal, Message, Divider, Button, Label } from 'semantic-ui-react';
import { Field } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import Wizard from '../../utils/wizard';
import api from '../../utils/api';
import { connect } from 'react-redux';
import { renderTextField, renderEditorField, renderDropzoneInputField } from './utils/render-fields';
import FeaturesTable from '../features-table';
import { flatMap } from 'lodash';
import ErrorBoundary from '../../utils/error-boundary';

const asyncValidate = (validation_url, setValidation) => (values, allValues, meta) => new Promise((resolve) => {
    api.upload(validation_url, values[0])
        .then(data => {
            setValidation({ ...data.body });
            resolve();
        })
        .catch(error => {
            const message = (error.response.body && error.response.body.message) || error;
            setValidation({ valid: false, reason: message });
            resolve(`Invalid instance "${message}"`);
        });
});

const composeValidators = (...validators) => (value, allValues, meta) => {
    if (meta.pristine)
        return;
    return validators.reduce((error, validator) => error || validator(value), undefined);
}

const composeValidatorsWithAsync = (asyncValidator, ...validators) => (value, allValues, meta) => {
    if (meta.pristine)
        return;
    const syncError = composeValidators(...validators)(value, allValues, meta);
    return !!syncError ? syncError : asyncValidator(value, allValues, meta);
};

const required = setValidation => value => {
    if (!value) {
        const message = 'Instance file is required';
        setValidation({ valid: false, reason: message });
        return message;
    }
};

const mustHaveFormats = (formats, setValidation) => value => {
    const [, extension] = value[0].name.match(/\.([0-9a-z]+)$/i);
    if (!extension || formats.indexOf(extension) === -1) {
        const message = `Format "${extension}" not supported, try one of [${formats.join(', ')}]`;
        setValidation({ valid: false, reason: message });
        return message;
    }
};

const DropFileForm = connect(state => ({
    formats: state.problem.data.formats.instance,
    validation_url: state.problem.data._links.validate_instance.url || undefined
}))(props => {
    const { formats, validation_url } = props;
    const [validation, setValidation] = useState({});
    const features = flatMap(validation.features || {}, (value, key) => ({ name: key, value }));

    return (
        <ErrorBoundary>
            <Field name="instanceFile" component={renderDropzoneInputField} label="File"
                placeholder="Drop a file here, or click to select a file to upload."
                validate={composeValidatorsWithAsync(asyncValidate(validation_url, setValidation), required(setValidation), mustHaveFormats(formats, setValidation))}
                required
            />
            {
                validation.valid ? (
                    <Message success visible={validation !== {}}>
                        <Message.Header>Validation successful</Message.Header>
                        <div>The file is valid and can be uploaded.</div>
                        <div>
                            <Divider horizontal />
                            <Header>Features</Header>
                            <FeaturesTable features={features} />
                        </div>
                    </Message>
                ) : null
            }
            <Message>
                <Message.Header>Select an instance file</Message.Header>
                <div>
                    {formats && formats.length > 1 &&
                        <span>The supported file extensions/formats for this problem are: </span>}
                    {formats && formats.length === 1 &&
                        <span>The supported file extension/format for this problem is: </span>}
                    {formats.map((ext, index) => <Label key={index} color="blue">{ext}</Label>)}
                </div>
                <p>Before proceeding with the instance creation, the file will be validated on the server.</p>
            </Message>
            <Divider horizontal />
        </ErrorBoundary>
    );
});

const InstanceForm = props => (<>
        <Field name="instanceName" component={renderTextField} label="Name" required />
        <Field name="instanceDescription" component={renderEditorField} label="Description" placeholder="Description is optional." />
        <Divider horizontal />
        </>);

const decorator = createDecorator({
    field: 'instanceFile',
    updates: {
        instanceName: (value, allValues, prevValues) => prevValues.instanceName ? prevValues.instanceName : value[0].name
    }
});

class CreateInstanceDialog extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);
        this.state = {
            modalOpen: false, confirmCancelOpen: false, submitConfirmation: false,
            submissionError: false,
            values: {}
        };
    }

    handleOpen(e) {
        this.setState({
            modalOpen: true,
        });
    }

    handleClose(e) {
        this.setState({
            modalOpen: false,
            confirmCancelOpen: false,
            submitConfirmation: false,
            submissionError: false
        });
    }

    handleCancel(e) {
        if (this.state.values)
            this.handleClose(e);
        else
            this.setState({
                confirmCancelOpen: true
            });
    }

    discardCancel(e) {
        this.setState({
            confirmCancelOpen: false
        });
    }

    submit(values) {
        const data = {
            name: values.instanceName,
            description: values.instanceDescription || ""
        };
        const p = api.postWithFile(this.props.problem._links.create_instance.url, data, values.instanceFile[0], 'instance');
        p.then(() => this.setState({ submitConfirmation: true })).catch((error) => this.setState({ submissionError: error.response.body.message }));
        return p;
    }

    render() {
        const { modalOpen, submitConfirmation, confirmCancelOpen, submissionError } = this.state;
        return (
            <>
                <Modal trigger={<Button onClick={this.handleOpen} icon="add" content="Add instance" />}
                    open={modalOpen}
                    closeOnDimmerClick={false} closeOnEscape={true} closeIcon='close'
                    onClose={this.handleClose}>
                    <Modal.Header>
                        <Header as="h2">Create a new instance</Header>
                    </Modal.Header>
                    <Modal.Content>
                        {!(submitConfirmation || submissionError) &&
                            <Wizard onSubmit={this.submit} onCancel={this.handleClose} onChangePage={(values) => this.setState({ values })} decorators={[decorator]}>
                                <Wizard.Page title={'Instance file'} description={'Select and validate your instance file'}>
                                    <DropFileForm />
                                </Wizard.Page>
                                <Wizard.Page title={'Other information'} description={'Enter instance information'}>
                                    <InstanceForm/>
                                </Wizard.Page>
                            </Wizard>
                        }
                        {submitConfirmation &&
                            <div>
                                <Message>
                                    <Message.Header>Instance created</Message.Header>
                                    The instance has been created.
                                </Message>
                                <Button positive icon="checkmark" content="Close" onClick={this.handleClose} />
                            </div>
                        }
                        {submissionError &&
                            <div>
                                <Message>
                                    <Message.Header>Something went wrong</Message.Header>
                                    <p>An error <code>{submissionError}</code> occurred.</p>
                                    <p>Try again later or report a ticket.</p>
                                </Message>
                                <Button negative icon="cancel" content="Close" onClick={this.handleClose} />
                            </div>
                        }
                    </Modal.Content>
                </Modal>
                <Modal basic size="small" open={this.props.touched && confirmCancelOpen}>
                    <Header content="Confirm cancel" />
                    <Modal.Content>
                        All the data you entered will be discarded. Are you sure?
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative inverted onClick={this.discardCancel} icon="cancel" content="No" />
                        <Button basic positive inverted onClick={this.handleClose} icon="checkmark" content="Yes" />
                    </Modal.Actions>
                </Modal>
            </>
        );
    }
}

export default CreateInstanceDialog;