import { handleActions, createAction } from 'redux-actions';
import api from '../utils/api';

// initial state

const initialState = { data: {}, loading: false, invalid: false };

//actions

const loadProblem = createAction('LOAD_PROBLEM', path => api.get(`problems/path/${path}`));
const saveProblem = createAction('SAVE_PROBLEM', problem => api.put(problem._links.self.url, problem));
const saveProblemField = createAction('SAVE_PROBLEM_FIELD', (problem, field, value) => api.put(problem._links.self.url, { id: problem.id, [field]: value }));

// reducer

const reducer = handleActions({
    'LOAD_PROBLEM_PENDING': (state ,action) => ({...state, loading: true, invalid: false }),
    'LOAD_PROBLEM_FULFILLED': (state, action) => ({ data: action.payload.body.result, loading: false, invalid: false }),
    'LOAD_PROBLEM_REJECTED': (state, action) => ({ data: {}, loading: false, invalid: { code: action.payload.status, message: action.payload.message } }),
    'SAVE_PROBLEM_FIELD_FULFILLED': (state, action) => ({...state, data: action.payload.body.result }),
    'SAVE_PROBLEM_FULFILLED': (state, action) => ({...state, data: action.payload.body.result }),
    // NOTE: also when instance is directly load, the problem will change (i.e., it's the problem of that instance)
    'LOAD_INSTANCE_FULFILLED': (state, action) => ({ data: action.payload.body.result.problem, invalid: false})
}, initialState);

export { reducer as default, loadProblem, saveProblem, saveProblemField };

