import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';

export default (props) => {
    const path = props.path || "";
    let aggregate = '';
    const breadCrumbs = path.split('/').map((item, index, list) => {
        aggregate = index === 0 ? item : aggregate + '/' + item;
        if (index < list.length - 1)
            return (
                <span key={index}>
                    <Breadcrumb.Section>
                        <Link to={`/problem/${aggregate}`}>{item}</Link>
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider />
                </span>
            );
        else {
            return (<Breadcrumb.Section active key={index}>{item}</Breadcrumb.Section>);
        }
    });
    return (
        <Breadcrumb children={breadCrumbs}/>
    );
};
