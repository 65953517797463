import { handleActions, createAction } from 'redux-actions';
import keycloak from '../utils/keycloak';

// initial state

const initialState = {
    authenticated: false,
    user: undefined,
    userinfo: undefined,
    administrator: false,
    initialized: false
};

// actions

const loggedIn = createAction('LOGGED_IN', (authenticated, user, userinfo) => {
    return {
        authenticated,
        user,
        userinfo
    }    
});

const initialized = createAction('KEYCLOAK_INITIALIZED', () => {
    return {
        initialized: true
    }
});

const requireLogout = createAction('REQUIRE_LOGOUT', () => {
    keycloak.logout();
    return {
        authenticated: false,
        user: undefined,
        userinfo: undefined
    };
});

// reducer

const reducer = handleActions({
    'KEYCLOAK_INITIALIZED': (state, action) => ({...state, ...action.payload}),
    'LOGGED_IN': (state, action) => ({...state, ...action.payload}),
    'REQUIRE_LOGOUT': (state, action) => ({...state, ...action.payload})
}, { ...initialState });

export { reducer as default, initialized, loggedIn, requireLogout }; 
