import React from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import cx from 'classnames';
import autobind from 'class-autobind';
import PropTypes from 'prop-types';
import { uniq, range } from 'lodash';

class Pager extends React.Component {
    static propTypes = {
        currentPage: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        recordCount: PropTypes.number.isRequired,
        loadPage: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        autobind(this);
    }

    render() {
        const show = 5;
        const numPages = Math.ceil(this.props.recordCount / this.props.pageSize);
        if (numPages === 1) {
            return null;
        }
        let startIndex = this.props.currentPage >= Math.floor(show / 2) && numPages >= show ?
            Math.max(Math.min(Math.floor(this.props.currentPage + 1 - show / 2), numPages - show), 0) :
            Math.max(this.props.currentPage - show, 0);
        let endIndex = Math.min(startIndex + show, numPages);

        let previous = null, next = null, first = null, last = null;
        if (numPages > show) {
            previous = (
                <Menu.Item as="a" icon>
                    <Icon name="angle left" className={cx({ disabled: this.props.currentPage === 0 })} onClick={this._loadPage.bind(this, this.props.currentPage - 1)}/>
                </Menu.Item>);
            next = (
                <Menu.Item as="a" icon>
                    <Icon name="angle right" className={cx({ disabled: this.props.currentPage === numPages - 1 })} onClick={this._loadPage.bind(this, this.props.currentPage + 1)} />
                </Menu.Item>);
            first = (<Menu.Item as="a" onClick={this._loadPage.bind(this, 0)} icon="angle double left"
                                className={cx({disabled: this.props.currentPage === 0})}/>);
            last = (<Menu.Item as="a" onClick={this._loadPage.bind(this, numPages - 1)} icon="angle double right"
                               className={cx({disabled: this.props.currentPage === numPages - 1})}/>);
        }
        let page_numbers = [];
        for (let i = 0; i < 2; i++)
            page_numbers.push(i);
        for (let i = startIndex; i < endIndex; i++)
            page_numbers.push(i);
        for (let i = numPages - 2; i < numPages; i++)
            page_numbers.push(i);
        page_numbers = uniq(page_numbers);
        if (page_numbers.length >= numPages - 1) {
            page_numbers = range(numPages);
        }
        let options = [];
        let prev_i = undefined;
        page_numbers.forEach(i => {
            if (typeof prev_i == 'number' && prev_i < i - 1) {
                options.push(<Menu.Item disabled>...</Menu.Item>);
            }
            options.push(<Menu.Item as="a" key={i} className={cx({ active: this.props.currentPage === i })}  onClick={this._loadPage.bind(this, i)}>{i + 1}</Menu.Item>);
            prev_i = i;
        });
       
        return (
            <Menu floated='right' pagination>
                {first}
                {previous}                
                {options}
                {next}
                {last}
            </Menu>
        );
    }

    _loadPage(page) {
        const numPages = Math.ceil(this.props.recordCount / this.props.pageSize);
        if (page >= 0 && page < numPages && page !== this.props.currentPage)
            this.props.loadPage(page);
    }
}

export default Pager;