import io from 'socket.io-client';
import cfg from '../config/config';
import autobind from 'class-autobind';
import keycloak from './keycloak';
import request from 'superagent';

// TODO: check for non-json error responses (i.e., generic "Not Found")

class Api {
    config = cfg;
    sockets = {};

    constructor() {
        autobind(this);
    }

    socket(namespace) {
        const sockets = this.sockets || {};
        if (namespace in sockets)
            return sockets[namespace];
        const socket = io.connect(`${this.config.socketMountpoint}${namespace}`);
        const oneventOrig = socket.onevent;
        socket.onevent = (packet) => {
            const args = packet.data || [];
            oneventOrig.call(this, packet); // original call
            packet.data = ["*"].concat(args);
            oneventOrig.call(this, packet); // additional call to catch-all
        };
        this.sockets[namespace] = socket;
        return socket;
    }

    _prepareRequest(req, headers, query, require_auth) {
        req.set(headers || {});
        req.query(query || {});
        if (require_auth && keycloak.authenticated)  
            return new Promise((resolve, reject) => {
                keycloak.updateToken(5).then(() => {
                    req.auth(keycloak.token, { type: 'bearer' });
                    resolve(req);
                }).catch(reject);
            });
        else
            return req;
    }

    // TODO: introduce also a search-aware method so that more specific queries can rely on client-side filtering
    async get(resource, headers = {}, query = {}, auth = true) {
        return this._prepareRequest(request.get(`${this.config.mountpoint}/${resource}`), headers, query, auth);
    }

    async search(term,  headers = {}, query = {}, auth = false) {
        const r = this._prepareRequest(request.get(`${this.config.mountpoint}/search/?term=${term}`), headers, query, auth);
        return new Promise((resolve, reject) => {           
            r.catch(reject);
            r.then((res) => {
                if (!res.body.success)
                    // TODO: this is just tentative, don't know whether to reject or to resolve with an empty content
                    reject(res.body);
                const search_results = res.body.results || {};
                let results = {};
                for (let c in search_results) {
                    results[c] = { name: c };
                    results[c]['results'] = (search_results[c] || []).map((item) => {
                        let r = {};
                        //r['id'] = item.id;
                        r['name'] = item.name;
                        switch (c) {
                            case 'problems':
                                r['path'] = `/problem/${item.path}`;
                                break;
                            default:
                                r['path'] = `/${c.replace(/s$/, '')}/${item.id}`;
                        }
                        return r;
                    });
                };
                resolve(results);
            });
        });
    }

    async getPaged(resource, page, per_page, sort, auth = true) {
        const headers = {
                'Range': (page * per_page) + "-" + ((page + 1) * per_page - 1),
                'Range-Unit': 'items'
            }, 
        query = { sort: sort };
        return this.get(resource, headers, query, auth);
    }

    async upload(resource, file, progress = () => {},  headers = {}, query = {}, auth = true) {
        const upload_headers = {
            "Content-Disposition": 'attachment; filename="' + file.name + '"',
            "Content-Type": file.type || "application/octet-stream"
        }
        return this._prepareRequest(
           request.post(`${this.config.mountpoint}/${resource}`).send(file).on('progress', (e) => progress(e.percent)),
         { ...headers, ...upload_headers }, query, auth);
    }

    async put(resource, data,  headers = {}, query = {}, auth = true) {
        return this._prepareRequest(request.put(`${this.config.mountpoint}/${resource}`).send(data), headers, query, auth);
    }

    async post(resource, data,  headers = {}, query = {}, auth = true) {
        return this._prepareParams(request.post(`${this.config.mountpoint}/${resource}`).send(data), headers, query, auth);
    }

    async postWithFile(resource, data, file, field,  headers = {}, query = {}, auth = true) {
        return this._prepareRequest(
            request.post(`${this.config.mountpoint}/${resource}`).attach(field, file).field('data', JSON.stringify(data)), 
            headers, query, auth);
    }
}

const api = new Api();

export default api;
