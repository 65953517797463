import React from 'react';
import Markdown from 'react-markdown';
import { Segment, Header, Icon } from 'semantic-ui-react';

const welcome_message = `
This is a respository of problems instances and solutions for optimization problems.

The main purpose of the repository is to keep track of certified (i.e., valid and timestamped) solutions for optimization problems along with their authors in order to foster results comparability and reproducibility in this research area.

It complements other problem repositories, such as [OR-Library](http://www.brunel.ac.uk/~mastjjb/jeb/info.html) and [CSPLib](http://csplib.org), so that also problem solutions are managed and retained in the system.

### Problems

Problems are organized in a hierarchical structure. You can find problems using the search control in the navigation or navigate the tree view at the top of this page.

### Use and authentication

You can freely browse the repository, but if you want to contribute with your solutions you should authenticate to the site. The authentication is granted from external providers, we currently support Microsoft Live and Google, but we plan to add more authentication options in the near future.

---

The system is currently in *Alpha* phase of development. The interface (both the UI and the API) might change in the future.

### Bugs and Issues

If you experience problems with the system, please [open an issue request](https://github.com/liuq/OptHub/issues/new) describing the problem.

  `;

export default (props) => (
    <div>
        <Segment raised>
            <Header as="h1" className="icon" textAlign='center'>
                <Icon className="icon-opthub" circular/>
                Welcome to the Optimization Hub
                <Header.Subheader>
                    The Optimization Problems repository.
                </Header.Subheader>
            </Header>
            <Markdown children={welcome_message}/>
        </Segment>
        {/* here it goes the feed */}
        <Segment>
            The content and the services of this web site are provided “as is” i.e., without warranty of any kind, either expressed or implied, including but not limited to the implied warranties of satisfactory quality, fitness for a particular purpose, title and non-infringement of third party intellectual property rights.
        </Segment>
    </div>
);