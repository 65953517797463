import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { Form as SemanticUIForm, Button, Step, Segment, Divider, Container, Dimmer, Loader } from 'semantic-ui-react';
import autobind from 'class-autobind';
import cx from 'classnames';

class WizardPage extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        icon: PropTypes.string,
        decorators: PropTypes.array       
    };

    constructor(props) {
        super(props);
        autobind(this);
    }

    render() {        
        return this.props.children;
    }
}

class Wizard extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
        onChangePage: PropTypes.func
    };

    static Page = WizardPage; // ({ children }) => children;

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            values: props.initialValues || {}
        }
        autobind(this);
    }

    next(values) {
        this.setState(state => ({
            page: Math.min(state.page + 1, this.props.children.length - 1),
            values
        }));
    }

    previous() {
        this.setState(state => ({
            page: Math.max(state.page - 1, 0)
        }));
    }

    validate(values) {
        const activePage = React.Children.toArray(this.props.children)[this.state.page];
        return activePage.props.validate ? activePage.props.validate(values) : {}
    }

    handleSubmit(values) {
        const { children, onSubmit, onChangePage } = this.props;
        const { page } = this.state;
        const isLastPage = page === React.Children.count(children) - 1;        
        onChangePage && onChangePage(values);
        if (isLastPage) {
            return onSubmit(values);
        } else {
            this.next(values);
        }
    }

    render() {
        const { children, onCancel, decorators } = this.props;
        const { page, values } = this.state;
        const activePage = React.Children.toArray(children)[page];
        const isLastPage = page === React.Children.count(children) - 1;
        // steps must have completed and active
        const steps = React.Children.map(children, (child, index) => {
            return {
                key: index,
                title: child.props.title || "",
                description: child.props.description || "",
                icon: child.props.icon || null,
                completed: index < page, 
                active: index === page
            };
        });      
        // const pages = React.Children.map(children, (child, index) => {
        //     return (<div className={cx({ hidden: index != page })}>{child}</div>);
        // });

        return (
            <Form
                initialValues={values}
                validate={this.validate}
                onSubmit={this.handleSubmit}
                decorators={decorators}
            >
                {({ handleSubmit, submitting, values, validating, invalid }) => (
                    <SemanticUIForm onSubmit={handleSubmit} widths="equal">
                        <Step.Group ordered items={steps}/>
                        <Segment>
                            <Dimmer active={validating} inverted>
                                <Loader>Validating</Loader>
                            </Dimmer>
                            {activePage}
                        </Segment>
                        {process.env.NODE_ENV == 'development' ? (
                            <Segment>
                            <pre>{JSON.stringify(values, 0, 2)}</pre>
                            </Segment>
                        ) : null}
                        <Divider clearing/>
                        <Container className="buttons">
                            <Button.Group floated='left'>
                            <Button onClick={this.previous} 
                                attached="left" icon="chevron left" labelPosition="left"
                                content="Previous" disabled={page === 0 || submitting || validating }/>
                            </Button.Group>
                            <Button.Group floated="right">
                                {!isLastPage ? 
                                    (<Button type="submit" icon="chevron right" labelPosition="right"
                                    content="Next" disabled={submitting || validating || invalid}/>)
                                :
                                    (
                                        <Button.Group>
                                        <SemanticUIForm.Button positive type="submit" icon="save"
                                                    labelPosition="left" content="Save" disabled={submitting || validating}/>
                                        <Button.Or/>
                                        <SemanticUIForm.Button negative type="button" icon="cancel"
                                                    labelPosition="right" content="Cancel" onClick={onCancel}
                                                    disabled={submitting || validating}/>   
                                        </Button.Group>                            
                                )}
                            </Button.Group>
                        </Container>                      
                    </SemanticUIForm>
                )}
            </Form>
        )
    }
}


export default Wizard;
