import { combineReducers } from 'redux';
import login from './login';
import problem from './problem';
import instance from './instance';
import { reducer as table } from '../components/table-manager';
import { connectRouter } from 'connected-react-router';

export default (history) => combineReducers({
    router: connectRouter(history),
    login,
    problem,
    instance,
    table,
});