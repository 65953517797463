import React from 'react';
import { connect } from 'react-redux';
import autobind from 'class-autobind';
import { withRouter, Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { loadInstance, saveInstanceField } from '../ducks/instance';
import ProblemTree from '../components/problem-tree';
import Breadcrumbs from '../components/breadcrumbs';
import ErrorPage from '../components/errors/error-page';
import { Container, Segment, Header, Divider, Icon, Form, Button, Statistic } from 'semantic-ui-react';
import Editable from '../utils/editable';
import Markdown from 'react-markdown';
import config from '../config/config';
import moment from 'moment';
import TableManager from '../components/table-manager';
import FeaturesTable from '../components/features-table';
import CreateSolutionDialog from '../components/dialogs/create-solution';
import ValidateSolutionDialog from '../components/dialogs/validate-solution';
import { isEmpty } from 'lodash';
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";


// TODO: manage donwloads (look at the proxy)
// TODO: manage solution creation and/or validation
// TODO: manage solution deletion (probably better in a dedicated user dashboard)

const columns = [
    {
        name: "Solution Name",
        t: item => item.name
    },
    {
        name: "Download",
        t: item => (item._links.content && <a href={config.downloadMountpoint + '/' + item._links.content.url}><Icon name="download"/></a>)
    },
    {
        name: "Last update",
        t: item => moment(item.last_update).format("MMM D, YYYY"),
    },
    {
        name: "Cost",
        t: item => (isFinite(item.cost) && <span>{item.cost}</span>)
    },
    {
        name: "Contributors",
        t:  item => (item.contributors && <span>{item.contributors.length === 1 ? <Icon name="user"/> : (item.contributors.length > 1 ? <Icon name="users"/> : <Icon name="question circle"/>)}{item.contributors.map((i) => i.name).join(', ')}</span>)
    },
    {
        name: "Method/Tags",
        t: item => (item.tags && <span>{item.tags.join(', ')}</span>)
    }
];

const Statistics = props => (
    <Segment>
        <Header as="h2">
            Instance Statistics
        </Header>
        <Statistic.Group widths='four'>
            <Statistic>
                <Statistic.Value>
                    <Icon name="cubes" />
                    {props.solution.count}
                </Statistic.Value>
                <Statistic.Label>Solutions</Statistic.Label>
            </Statistic>

            <Statistic>
                <Statistic.Value>
                    <Icon name="cubes" />
                    {props.solutions.count}
                </Statistic.Value>
                <Statistic.Label>Solutions</Statistic.Label>
            </Statistic>

            <Statistic>
                <Statistic.Value>
                    <Icon name="users" />
                    {props.contributors.count}
                </Statistic.Value>
                <Statistic.Label>Contributors</Statistic.Label>
            </Statistic>

            <Statistic>
                <Statistic.Value>
                    <Icon name="fire" />
                    {props.contributors.average_solutions.toFixed(1)}
                </Statistic.Value>
                <Statistic.Label>Solutions per Contributor</Statistic.Label>
            </Statistic>
        </Statistic.Group>
    </Segment>
);

class Instance extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        instance: PropTypes.object.isRequired,
        authenticated: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);
        autobind(this);
        props.dispatch(loadInstance(props.match.params.id));
        this.state = { 'id': props.match.params.id, 'authenticated': props.authenticated };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.match.params.id !== state.id) {
            props.dispatch(loadInstance(props.match.params.id));
            return { 'id': props.match.params.id };
        }
        if (props.authenticated !== state.authenticated) {
            props.dispatch(loadInstance(props.match.params.id));
            return { 'authenticated': props.authenticated };
        }   
        return {}     
    }

    saveField(field, value) {
        const { instance } = this.props;
        this.props.dispatch(saveInstanceField(instance, field, value));
    }

    render() {
        const { instance, problem } = this.props;

        if (this.props.invalid && this.props.invalid.code === 404)
            return <Redirect to={{
                pathname: '/404',
                state: { from: this.props.location }
            }}/>;
        else if (this.props.invalid)
            return <ErrorPage from={this.props.location} history={this.props.history} error={this.props.invalid} />;
        if (this.props.loading || isEmpty(instance))
            return null;
        if (this.props.match.params.path !== problem.path)
            return (<Redirect to={`/instance/${problem.path}/${this.props.match.params.id}`}/>);

        const Name = Editable(<Form.Input type="text" defaultValue={instance.name}/>);
        const Description = Editable(<SimpleMDE value={instance.description}/>);
        let dialog = [];
        if (instance._links.create_solution && problem.formats.solution)
            dialog.push(
                <Button.Group>
                    <CreateSolutionDialog problem={problem} instance={instance}/>
                </Button.Group>
            );
        if (instance._links.validate_solution && problem.formats.solution)
            dialog.push(
                <Button.Group>
                    <ValidateSolutionDialog problem={problem} instance={instance} url={instance._links.validate_solution.url}/>
                </Button.Group>
            );
        const SolutionTable = TableManager('SolutionTable');

        return (
            <Container>
                <ProblemTree centerNode={problem.abbreviation} height="200px"/>
                <Divider horizontal/>
                <Container>
                    <Breadcrumbs path={this.props.instance.problem.path + '/' + instance.name}/>
                </Container>
                <Divider horizontal/>
                <Container>
                    <Name value={instance.name} onSave={this.saveField.bind(this, "name")} editable={!!instance._links.update}>
                        <Header as="h1">{instance.name}</Header>
                    </Name>
                </Container>
                <Divider horizontal/>
                <Description value={instance.description} onSave={this.saveField.bind(this, "description")}  editable={!!instance._links.update}>
                    <Markdown children={instance.description || ""}/>
                </Description>
                <Divider horizontal/>
                <Header as="h2">Features</Header>
                <FeaturesTable features={instance.features}/>
                {instance.statistics && (<Statistics {...instance.statistics}/>)}
                <Divider horizontal/>
                {instance._links.content && 
                    <Segment>
                        <Header as="h2">
                            <Icon name="download"/> Download
                        </Header>
                        <Button as="a" href={config.downloadMountpoint + '/' + instance._links.content.url}>
                            <Button.Content visible><Icon name="file"/>Instance</Button.Content>
                        </Button>
                    </Segment>
                }
                {instance._links.solutions && (
                    <Container>
                        <Header as="h2">Solutions</Header>
                        <SolutionTable url={instance._links.solutions.url + '?sort=cost'} columns={columns} pageSize={10} striped/>
                    </Container>)}
                <Divider horizontal/>
                {dialog}
            </Container>
        );
    }
}

export default connect(state => ({
    instance: state.instance.data,
    problem: state.problem.data,
    loading: state.instance.loading,
    invalid: state.instance.invalid,
    authenticated: state.login.authenticated
}))(withRouter(Instance));