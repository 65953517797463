import React from 'react';
import api from '../utils/api';
import PropTypes from 'prop-types';
import { Divider, Table, Dimmer, Loader, Rail, Menu } from 'semantic-ui-react';
import Pager from './pager';
import autobind from 'class-autobind';
import { connect } from 'react-redux';
import { handleActions, createAction } from 'redux-actions';

const reducer = handleActions({
        'REGISTER_TABLE_DATA': (state, action) => ({...state, [action.meta.table]: { data:[], loading: false, error: false, page: 0, recordCount: 0 }}),
        'LOAD_TABLE_DATA_PENDING': (state, action) => ({
            ...state,
            [action.meta.table]: {...state[action.meta.table], loading: true, error: false}
        }),
        'LOAD_TABLE_DATA_FULFILLED': (state, action) => {
            return ({
            ...state,
            [action.meta.table]: {
                data: action.payload.data,
                page: action.payload.page,
                recordCount: action.payload.recordCount,
                loading: false,
                error: false
            }
        })
        },
        'LOAD_TABLE_DATA_REJECTED': (state, action) => ({
            ...state,
            [action.meta.table]: {...state[action.meta.table], loading: true, error: false}
        }),
        'UNREGISTER_TABLE_DATA': (state, action) => {
            delete state[action.meta.table];
            return state;
        },
    },
    {}
);

const TableManager = (name) => connect(state => ({
    ...state.table[name]
    } || {}))(class TableManagerImplementation extends React.Component {
        static propTypes = {
            url: PropTypes.string.isRequired,
            data: PropTypes.array,
            page: PropTypes.number,
            pageSize: PropTypes.number.isRequired,
            columns: PropTypes.array.isRequired,
            loading: PropTypes.bool
        };

        static actions = {
            registerTable: createAction('REGISTER_TABLE_DATA', () => {}, () => ({ table: name })),
            loadData: createAction('LOAD_TABLE_DATA', (url, pageSize, page) =>
                new Promise((resolve, reject) =>
                    api.getPaged(url, page, pageSize).then((response) => resolve({
                        data: response.body.result, page,
                        recordCount: parseInt(response.header['content-range'].split('/')[1], 10)
                    })).catch(error => reject(error))), () => ({ table: name })),
            unregisterTable: createAction('UNREGISTER_TABLE_DATA', () => {}, () => ({ table: name }))
        };

        constructor(props) {
            super(props);
            autobind(this);
            this.state = { currentURL: props.url, loading: true };
        }

        _loadInitialData() {
            this.props.dispatch(TableManagerImplementation.actions.loadData(this.props.url, this.props.pageSize, 0));
        }

        _loadPage(page) {
            this.props.dispatch(TableManagerImplementation.actions.loadData(this.props.url, this.props.pageSize, page));
        }

        componentDidMount() {
            this.props.dispatch(TableManagerImplementation.actions.registerTable());
            this._loadInitialData();
        }

        componentWillUnmount() {
            this.props.dispatch(TableManagerImplementation.actions.unregisterTable());
        }

        shouldComponentUpdate(nextProps, nextState) {
            if (nextProps.data !== this.props.data || nextProps.loading !== this.props.loading)
                return true;
            if (nextProps.recordCount !== this.props.recordCount)
                return true;
            return false;
        }

        static getDerivedStateFromProps(props, state) {
            if (props.url != state.currentURL) {
                this._loadInitialData();
                return { currentURL: props.url };
            }
            return {};
        }

        // componentWillReceiveProps(nextProps) {
        //     if (nextProps.url !== this.props.url) {
        //         this._loadInitialData();
        //     }
        // }

        render() {
            const { url, dispatch, data, page, pageSize, recordCount, loading, columns, error, ...otherProps } = this.props;
            if (error && !loading)
                return null;
            return (
                <Dimmer.Dimmable>
                    <Divider/>
                    <Dimmer inverted active={loading}>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                    <Rail attached position="right">
                        <Menu text compact>
                            <Menu.Item icon="refresh" onClick={this._loadPage.bind(this, page)}/>
                        </Menu>
                    </Rail>
                    <Table columns={columns.length || 0} {...otherProps}>
                        <Table.Header>
                            <Table.Row>
                                {columns && columns.map((it, index) => (<Table.HeaderCell key={index}>{it.name}</Table.HeaderCell>))}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {(data || []).map((item, index) => (
                                <Table.Row key={index}>
                                    {columns && columns.map((c, index) => (<Table.Cell key={index}>{c.t(item)}</Table.Cell>))}
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                    <Pager currentPage={page} pageSize={pageSize} recordCount={recordCount} loadPage={this._loadPage}/>
                </Dimmer.Dimmable>
            );
        }
    }
);

export { TableManager as default, reducer };