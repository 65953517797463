import * as d3 from 'd3';
import React from 'react';
import { withRouter } from 'react-router';
import $ from 'jquery';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import '../styles/tree.css';
import api from '../utils/api';
import autobind from 'class-autobind';

let node_id = 0;

// TODO: add pan, zoom and drag and drop features along
//       the lines sketched in http://bl.ocks.org/robschmuecker/7880033

// TODO: graphically differentiate abstract problems from concrete ones
// TODO: add loading dimmer

class d3Tree {
    constructor(treeData, history, container, center) {
        const margin = {top: 20, right: 120, bottom: 20, left: 120};
        this.width = $(container).width() - margin.right - margin.left;
        this.height = $(container).height() - margin.top - margin.bottom;
        this.duration = 750;
        this.centerNode = center;
        this.collapseLevel = 4;
        this.container = container;
        this.history = history;

        this.tree = d3.layout.tree().size([this.height, this.width]);

        this.svg = d3.select(container).append("svg")
            .attr("width", $(container).width())
            .attr("height", $(container).height())
            .classed('overlay', true)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        this.root = treeData;
        this.root.x0 = this.height / 2;
        this.root.y0 = 0;

        let level = 1;

        const collapse = (d) => {
            if (d.children && ! d.open) {
                if (level > this.collapseLevel) {
                    d._children = d.children;
                    d.children = null;
                    level++;
                    d._children.forEach(collapse);
                    level--;
                } else {
                    d._children = null;
                    level++;
                    d.children.forEach(collapse);
                    level--;
                }
            }
        };

        let centerNode = this.root;
        if (center)
            centerNode = this._centerOn(center);
        (this.root.children || []).forEach(collapse);
        this._update(this.root);
        this._centerNode(centerNode);
    }

    clear() {
        d3.select(this.container).html(null);
    }

    _toggleChildren(d) {
        if (d.children) {
            d._children = d.children;
            d.children = null;
        } else {
            d.children = d._children;
            d._children = null;
        }
    }

    _showChildren(d) {
        if (d._children && ! d.children)
            this._toggleChildren(d);
    }

    _update(source) {
        // Compute the new tree layout.
        let nodes = this.tree.nodes(this.root),
            links = this.tree.links(nodes);

        // Normalize for fixed-depth.
        nodes.forEach((d) => { d.y = d.depth * 120 });

        // Update the nodes...
        let node = this.svg.selectAll("g.node")
            .data(nodes, (d) => (d.id || (d.id = ++node_id)));

        // Enter any new nodes at the parent's previous position.
        let nodeEnter = node.enter().append("g")
            .attr("class", "node")
            .attr("id", d => 'node-' + d.id)
            .attr("transform", d => "translate(" + source.y0 + "," + source.x0 + ")");

        let circleColor = (d) => {
            if (d.abbreviation === this.centerNode)
                return "steelblue";
            if (d._children)
                return "lightsteelblue";
            if (d.children)
                return "white";
            return "#ccc";
        };

        nodeEnter.append("circle")
            .attr("r", 7)
            .style("fill", circleColor)
            .on("mouseover", (d) => {
                if (!d.children && !d._children)
                    return;
                d3.select("#node-" + d.id + " > circle", this.svg).classed("over", true);
            })
            .on("mouseout", (d) => {
                if (!d.children && !d._children)
                    return;
                d3.select("#node-" + d.id + " > text", this.svg).classed("over", false);
            })
            .on("click", (d) => {
                if (!d.children && !d._children)
                    return;
                this._toggleChildren(d);
                this._centerNode(d);
                this._update(d);
            });

        nodeEnter.append("text")
            .attr("x", d => d.children || d._children ? -10 : 10)
            .attr("dy", ".35em")
            .attr("text-anchor", d => d.children || d._children ? "end" : "start")
            .text(d => d.abbreviation === "All" ? "Root" : (d.name.length < 15 ? d.name : d.abbreviation))
            .style("fill-opacity", 1)
            .classed("root", d => d.abbreviation === "All")
            .classed("current", d => d.abbreviation === this.centerNode)
            .on("mouseover", (d) => {
                if (d.abbreviation === "All" || d.abbreviation === this.centerNode)
                    return;
                d3.select("#node-" + d.id + " > text", this.svg).classed("over", true);
            })
            .on("mouseout", (d) => {
                if (d.abbreviation === "All" || d.abbreviation === this.centerNode)
                    return;
                d3.select("#node-" + d.id + " > text", this.svg).classed("over", false);
            })
            .on("click", (d) => {
                if (d.abbreviation === "All" || d.abbreviation === this.centerNode)
                    return;
                this.history.push('/problem/' + d.path);
            });

        // Transition nodes to their new position.
        let nodeUpdate = node.transition()
            .duration(this.duration)
            .attr("transform", d => "translate(" + d.y + "," + d.x + ")");

        nodeUpdate.select("circle")
            .attr("r", 7)
            .style("fill", circleColor);

        nodeUpdate.select("text")
            .style("fill-opacity", 1);

        // Transition exiting nodes to the parent's new position.
        let nodeExit = node.exit().transition()
            .duration(this.duration)
            .attr("transform", d => "translate(" + source.y + "," + source.x + ")")
            .remove();

        nodeExit.select("circle")
            .attr("r", 1e-6);

        nodeExit.select("text")
            .style("fill-opacity", 1e-6);

        // Update the links...
        let link = this.svg.selectAll("path.link")
            .data(links, d => d.target.id);

        const _diagonal = d3.svg.diagonal()
            .projection(d => [d.y, d.x]);

        // Enter any new links at the parent's previous position.
        link.enter().insert("path", "g")
            .attr("class", "link")
            .attr("d", (d) => {
                const o = { x: source.x0, y: source.y0 };
                return _diagonal({ source: o, target: o });
            });

        // Transition links to their new position.
        link.transition()
            .duration(this.duration)
            .attr("d", _diagonal);

        // Transition exiting nodes to the parent's new position.
        link.exit().transition()
            .duration(this.duration)
            .attr("d", (d) => {
                const o = { x: source.x, y: source.y };
                return _diagonal({ source: o, target: o });
            })
            .remove();

        // Stash the old positions for transition.
        nodes.forEach((d) => {
            d.x0 = d.x;
            d.y0 = d.y;
        });
    }

    _centerNode(source) {
        if (!source)
            return;
        // do not center leaf nodes
        if (!source.children && !source._children)
            this._centerNode(source.parent);
        // Define the zoom function for the zoomable tree
        function zoom() {
            this.svg.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")");
        }
        // define the zoomListener which calls the zoom function on the "zoom" event constrained within the scaleExtents
        let zoomListener = d3.behavior.zoom().scaleExtent([0.1, 3]).on("zoom", zoom);
        let scale = zoomListener.scale();
        let x = -source.y0;
        let y = -source.x0;

        x = x * scale + this.width / 2;
        y = y * scale + this.height / 2;
        d3.select('g').transition()
            .duration(this.duration)
            .attr("transform", "translate(" + x + "," + y + ")scale(" + scale + ")");
        zoomListener.scale(scale);
        zoomListener.translate([x, y]);
    }

    _centerOn(nodeAbbreviation) {
        const findNode = (abbreviation, node) => {
            if (node.abbreviation === abbreviation) {
                node.open = true;
                return [node];
            }
            let children = node.children || node._children || [];
            for (let i in children) {
                let found = findNode(abbreviation, children[i]);
                if (found.length > 0) {
                    node.open = true;
                    return [node, ...found];
                }
            }
            return [];
        };
        let path = findNode(nodeAbbreviation, this.root);
        if (path.length > 0)
            return path[path.length - 1];
        else
            return null;
    }
}

class ProblemTree extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    def = {
        width: "100%",
        height: "90vh"
    };

    constructor(props) {
        super(props);
        autobind(this);
        this.state = {tree: {}, loading: false, unmounted: true };
        this.treeRef = React.createRef()
    }

    componentDidMount() {
        this.setState({ loading: true, unmounted: false });
        api.get('problems/tree', {}, {}, true).then((data) => {
            if (this.state.unmounted)
                return;
            this.setState({ loading: false, tree: data.body });
        }).catch((error) => {
            // TODO: do something
        });
    }

    componentWillUnmount() {
        this.setState({ unmounted: true });
    }

    render() {
        if (!isEmpty(this.state.tree)) {
            // FIXME: currently a brand new tree is created at each render, it should be kept somewhere and only the centering should change
            if (this.tree)
                this.tree.clear();
            this.tree = new d3Tree(this.state.tree, this.props.history, this.treeRef.current, this.props.centerNode);
        }
        return (
            <div ref={this.treeRef} className="problem-tree" style={{ height: this.props.height || this.def.height,  width: this.props.width || this.def.width }}></div>
        );
    }
}

export default withRouter(ProblemTree);
