import React from 'react';
import { connect } from 'react-redux';
import autobind from 'class-autobind';
import { withRouter, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loadProblem, saveProblemField } from '../ducks/problem';
import ProblemTree from '../components/problem-tree';
import Breadcrumbs from '../components/breadcrumbs';
import ErrorPage from '../components/errors/error-page';
import { Container, Segment, Header, Divider, Icon, Form, Button, Statistic } from 'semantic-ui-react';
import Editable from '../utils/editable';
import Markdown from 'react-markdown';
import config from '../config/config';
import moment from 'moment';
import TableManager from '../components/table-manager';
import CreateInstanceDialog from '../components/dialogs/create-instance';
import $ from 'jquery';
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import { round, isInteger, isNumber } from 'lodash';

// TODO: manage downloads (look at the proxy)
// TODO: when a new instance is added, the problem should be reloaded because statistics are changing (i.e., a new instance is available)
// TODO: the previous point can be handled recovering socket.io and signals/messages
// TODO: for abstract problem (containers) find an alternative view with some more information

const roundValue = (value) => isNumber(value) & !isInteger(value) ? round(value, 2) : value;

const columns = path => [
    {
        name: "Instance Name",
        t: item => (<Link to={`/instance/${path}/${item.id}`}>{item.name}</Link>)
    },
    // {
    //     name: "Download",
    //     t: item => (item._links.content && <a href={config.downloadMountpoint + '/' + item._links.content.url}><Icon name="download"/></a>)
    // },
    {
        name: "Last update",
        t: item => moment(item.last_update).format("MMM D, YYYY"),
    },
    {
        name: "Contributors",
        t:  item => (item.contributors && <span>{item.contributors.length === 1 ? <Icon name="user"/> : (item.contributors.length > 1 ? <Icon name="users"/> : <Icon name="question circle"/>)}{item.contributors.map((i) => i.name).join(', ')}</span>)
    },
    {
        name: "Category",
        t: item => item.category
    },
    {
        name: "Best Solution Cost",
        t: item => ((item.best_solution && (isNumber(item.best_solution.cost)) ? <span><Icon name="trophy"/>{roundValue(item.best_solution.cost)}</span> : "No solution"))
    },
    {
        name: "Best Solution Contributors",
        t:  item => (item.best_solution && item.best_solution.contributors && <span>{item.best_solution.contributors.length === 1 ? <Icon name="user"/> : (item.best_solution.contributors.length > 1 ? <Icon name="users"/> : <Icon name="question circle"/>)}{item.best_solution.contributors.map((i) => i.name).join(', ')}</span>)
    },
];

const Statistics = props => (
    <Segment>
        <Header as="h2">
            Problem Statistics
        </Header>
        <Statistic.Group widths="four" size="small">
            <Statistic>
                <Statistic.Value>
                    <Icon name="cube" />
                    {props.instances.count}
                </Statistic.Value>
                <Statistic.Label>Instances</Statistic.Label>
            </Statistic>

            <Statistic>
                <Statistic.Value>
                    <Icon name="cubes" />
                    {props.solutions.count}
                </Statistic.Value>
                <Statistic.Label>Solutions</Statistic.Label>
            </Statistic>

            <Statistic>
                <Statistic.Value>
                    <Icon name="users" />
                    {props.contributors.count}
                </Statistic.Value>
                <Statistic.Label>Contributors</Statistic.Label>
            </Statistic>

            <Statistic>
                <Statistic.Value>
                    <Icon name="fire" />
                    {props.contributors.average_solutions.toFixed(1)}
                </Statistic.Value>
                <Statistic.Label>Average Sols per Contributor</Statistic.Label>
            </Statistic>
        </Statistic.Group>
    </Segment>
);

class Problem extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        problem: PropTypes.object,
        authenticated: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);
        autobind(this);
        props.dispatch(loadProblem(props.match.params.path));
        this.state = { 'path': props.match.params.path, 'authenticated': props.authenticated };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.match.params.path !== state.path) {
            props.dispatch(loadProblem(props.match.params.path));
            return { 'path': props.match.params.path };
        }
        if (props.authenticated !== state.authenticated) {
            props.dispatch(loadProblem(props.match.params.path));
            return { 'authenticated': props.authenticated };
        }   
        return {}     
    }

    saveField(field, value) {
        const { problem } = this.props;
        this.props.dispatch(saveProblemField(problem, field, value));
    }

    render() {
        const { problem } = this.props;
        if (this.props.invalid && this.props.invalid.code === 404)
            return <Redirect to={{
                pathname: '/404',
                state: { from: this.props.location }
            }}/>;
        else if (this.props.invalid)
            return <ErrorPage from={this.props.location} history={this.props.history} error={this.props.invalid} />;
        if (this.props.loading || $.isEmptyObject(problem))
            return null;
        const Name = Editable(<Form.Input type="text" defaultValue={problem.name}/>);
        //const Description = Editable(<Form.TextArea autoHeight defaultValue={problem.description}/>);
        const Description = Editable(<SimpleMDE value={problem.description}/>);
        let dialog = null;
        if (problem._links.create_instance && problem.formats.instance)
            dialog = (
                <Button.Group>
                    <CreateInstanceDialog problem={problem}/>
                </Button.Group>
            );
        const InstanceTable = TableManager('InstanceTable');
        return (
            <Container>
                <ProblemTree centerNode={problem.abbreviation} height="200px"/>
                <Divider horizontal/>
                <Container>
                    <Breadcrumbs path={this.props.match.params.path}/>
                </Container>
                <Divider horizontal/>
                <Container>
                    <Name value={problem.name} onSave={this.saveField.bind(this, "name")} editable={!!problem._links.update}>
                        <Header as="h1">{problem.name}</Header>
                    </Name>
                </Container>
                <Divider horizontal/>
                <Description value={problem.description} onSave={this.saveField.bind(this, "description")}  editable={!!problem._links.update}>
                    <Markdown children={problem.description || ""}/>
                </Description>
                {problem._statistics && (<Statistics {...problem._statistics}/>)}
                {(problem._links.features_table || problem._links.instances_archive || problem._links.solutions_archive) &&
                    <Segment>
                        <Header as="h2">
                            <Icon name="download"/> Download
                        </Header>
                        {problem._links.features_table && problem._links.features_table.url && 
                            <Button as="a" href={config.downloadMountpoint + '/' + problem._links.features_table.url}>
                                <Button.Content visible><Icon name="file excel"/>Features</Button.Content>
                            </Button>
                        }
                        {problem._links.instances_archive && problem._links.instances_archive.url && 
                            <Button as="a" href={config.downloadMountpoint + '/' + problem._links.instances_archive.url}>
                                <Button.Content visible><Icon name="file zip"/>Instances</Button.Content>
                            </Button>
                        }
                        {problem._links.solutions_archive && problem._links.solutions_archive.url && 
                            <Button as="a" href={config.downloadMountpoint + '/' + problem._links.solutions_archive.url}>
                                <Button.Content visible><Icon name="file zip"/>Solutions</Button.Content>
                            </Button>
                        }
                    </Segment>
                }
                <Divider horizontal/>
                {problem._links.instances && (
                    <Container>
                        <Header as="h2">Instances</Header>
                        <InstanceTable url={problem._links.instances.url} columns={columns(this.props.match.params.path)} pageSize={10}/>
                    </Container>)}
                <Divider horizontal/>
                {dialog}
            </Container>
        );
    }
}

export default connect(state => ({
    problem: state.problem.data,
    loading: state.problem.loading,
    invalid: state.problem.invalid,
    authenticated: state.login.authenticated
}))(withRouter(Problem));