import React, { useState } from 'react';
import autobind from 'class-autobind';
import { Header, Modal, Message, Divider, Button, Label } from 'semantic-ui-react';
import { Field } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import Wizard from '../../utils/wizard';
import api from '../../utils/api';
import { connect } from 'react-redux';
import { renderTextField, renderEditorField, renderDropzoneInputField, renderTagField } from './utils/render-fields';
import FeaturesTable from '../features-table';
import { flatMap, trim } from 'lodash';
import ErrorBoundary from '../../utils/error-boundary';

const asyncValidate = (validation_url, setValidation) => (values, allValues, meta) => new Promise((resolve) => {
    api.upload(validation_url, values[0])
        .then(data => {
            setValidation({ ...data.body });
            resolve();
        })
        .catch(error => {
            const message = (error.response.body && error.response.body.message) || error;
            setValidation({ valid: false, reason: message });
            resolve(`Invalid solution "${message}"`);
        });
});

const composeValidators = (...validators) => (value, allValues, meta) => {
    if (meta.pristine)
        return;
    return validators.reduce((error, validator) => error || validator(value), undefined);
}

const composeValidatorsWithAsync = (asyncValidator, ...validators) => (value, allValues, meta) => {
    if (meta.pristine)
        return;
    const syncError = composeValidators(...validators)(value, allValues, meta);
    return !!syncError ? syncError : asyncValidator(value, allValues, meta);
};

const required = setValidation => value => {
    if (!value) {
        const message = 'Solution file is required';
        setValidation({ valid: false, reason: message });
        return message;
    }
};

const mustHaveFormats = (formats, setValidation) => value => {
    const [, extension] = value[0].name.match(/\.([0-9a-z]+)$/i);
    if (!extension || formats.indexOf(extension) === -1) {
        const message = `Format "${extension}" not supported, try one of [${formats.join(', ')}]`;
        setValidation({ valid: false, reason: message });
        return message;
    }
};

const SolutionFileForm = connect(state => ({
    formats:  state.problem.data.formats.solution,   
    instance: state.instance.data.name,
    validation_url: state.instance.data._links.validate_solution.url || undefined
}))(props => {    
    const { formats, instance, validation_url } = props;
    const [validation, setValidation] = useState({});
    const cost_components = flatMap(validation.cost_components || {}, (value, key) => ({ name: key, value }));

    return (
        <ErrorBoundary>
            <Field name="solutionFile" component={renderDropzoneInputField} label="File"
                    placeholder="Drop a file here, or click to select a file to upload."
                    validate={composeValidatorsWithAsync(asyncValidate(validation_url, setValidation), required(setValidation), mustHaveFormats(formats, setValidation))}
                    formats={formats}
                    filePrefix={instance}
                    required
            />
            {validation.valid ? (
                    <Message success visible={validation !== {}}>
                        <Message.Header>Validation successful</Message.Header>
                        <div>The file is valid and can be uploaded.</div>
                        <div>
                            <Divider horizontal/>
                            <Header>Features</Header>
                            <FeaturesTable features={cost_components}/>
                        </div>
                    </Message>
            ) : null }
            <Message>
                    <Message.Header>Select a solution file</Message.Header>
                    <div>
                        {formats && formats.length > 1 &&
                        <span>The supported file extensions/formats for this problem are: </span>}
                        {formats && formats.length === 1 &&
                        <span>The supported file extension/format for this problem is: </span>}
                        {formats.map((ext, index) => <Label key={index} color="blue">{ext}</Label>)}
                    </div>
                    <p>Before proceeding the solution will be validated on the server.</p>
                </Message>
                <Divider horizontal/>
        </ErrorBoundary>
    );
});

const SolutionForm = props => (
    <div>
        <Field name="solutionName" component={renderTextField} label="Name" value={props.wizard && props.wizard.solutionFile[0].name} placeholder="Solution name" required/>
        <Field name="solutionDescription" component={renderEditorField} label="Description" placeholder="Description is optional."/>
        <Divider horizontal/>
    </div>
);

const decorator = createDecorator({
    field: 'solutionFile',
    updates: {
        solutionName: (value, allValues, prevValues) => prevValues.solutionName ? prevValues.solutionName : value[0].name
    }
});

const SolutionMethodForm = props => {
    const search = (value) => {
        if (trim(value)) {
            return new Promise((resolve, reject) => {
                api.get('solutions/tags/search/' + trim(value)).then((res) => {
                    resolve(res.body.results.map((item, index) => ({
                        value: item,
                        text: item
                    })));
                }).catch((error) => reject(error));
            });
        } else
            return Promise.resolve([]);
    };
    const initialOptions = () => {
        return new Promise((resolve, reject) => {
            api.get('solutions/tags/most_frequent').then((res) => {
                resolve(res.body.results.map((item, index) => ({
                    value: item,
                    text: item
                })));
            }).catch((error) => reject(error));
        });
    };
    return (<div>
        <Field name="solutionMethod" component={renderTagField} label="Solution method"
               placeholder="Solution method (multiple options)" search={search} initialOptions={initialOptions} required/>
        <Message>
            <Message.Header>Describe the solution method</Message.Header>
            <div>
                You can choose a set of tags, that briefly describe the solution method employed to find this solution.
            </div>
        </Message>
        <Divider horizontal/>
    </div>)
};

class CreateSolutionDialog extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);
        this.state = { modalOpen: false, confirmCancelOpen: false, submitConfirmation: false,
            submissionError: false };
    }

    handleOpen(e) {
        this.setState({
            modalOpen: true,
        });
    }

    handleClose(e) {
        this.setState({
            modalOpen: false,
            confirmCancelOpen: false,
            submitConfirmation: false,
            submissionError: false
        });
    }

    handleCancel(e) {
        if (this.state.values)
            this.handleClose(e);
        else
            this.setState({
                confirmCancelOpen: true
            });
    }

    discardCancel(e) {
        this.setState({
            confirmCancelOpen: false
        });
    }

    submit(values) {
        const data = {
            name: values.solutionName,
            description: values.solutionDescription || "",
            tags: values.solutionMethod
        };
        const p = api.postWithFile(this.props.instance._links.create_solution.url, data, values.solutionFile[0], 'solution');
        p.then(() => this.setState({ submitConfirmation: true })).catch((error) => this.setState({ submissionError: error.response.body.message }));
        return p;
    }

    render() {
        const { modalOpen, submitConfirmation, confirmCancelOpen, submissionError } = this.state;
        return (
            <>
                <Modal trigger={<Button onClick={this.handleOpen} icon="add" content="Add solution"/>}
                       open={modalOpen}
                       closeOnDimmerClick={false} closeOnEscape={true} closeIcon='close'
                       onClose={this.handleClose}>
                    <Modal.Header>
                        <Header as="h2">Contribute a solution</Header>
                    </Modal.Header>
                    <Modal.Content>                        
                        {!(submitConfirmation || submissionError) &&
                            <Wizard onSubmit={this.submit} onCancel={this.handleClose}
                            onChangePage={(values) => this.setState({ values })} decorators={[decorator]}>
                                <Wizard.Page title={'Solution file'} description={'Select and validate your solution file'}>
                                    <SolutionFileForm/>
                                </Wizard.Page>
                                <Wizard.Page title={'Other information'} description={'Enter solution information'}>
                                    <SolutionForm/>
                                </Wizard.Page>
                                <Wizard.Page title={'Solution method'} description={'Describe the solution method'}>
                                    <SolutionMethodForm/>
                                </Wizard.Page>
                            </Wizard>
                        }
                        {submitConfirmation &&
                            <div>
                                <Message>
                                    <Message.Header>Solution created</Message.Header>
                                    The solution has been contributed.
                                </Message>
                                <Button positive icon="checkmark" content="Close" onClick={this.handleClose}/>
                            </div>
                        }
                        {submissionError &&
                        <div>
                            <Message>
                                <Message.Header>Something went wrong</Message.Header>
                                <p>An error <code>{submissionError}</code> occurred.</p>
                                <p>Try again later or report a ticket.</p>
                            </Message>
                            <Button negative icon="cancel" content="Close" onClick={this.handleClose}/>
                        </div>
                        }
                    </Modal.Content>
                </Modal>
                <Modal basic size="small" open={this.props.touched && confirmCancelOpen}>
                    <Header content="Confirm cancel"/>
                    <Modal.Content>
                        All the data you entered will be discarded. Are you sure?
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative inverted onClick={this.discardCancel} icon="cancel" content="No"/>
                        <Button basic positive inverted onClick={this.handleClose} icon="checkmark" content="Yes"/>
                    </Modal.Actions>
                </Modal>
            </>
        );
    }
}

export default CreateSolutionDialog;