import React, { useState } from 'react';
import autobind from 'class-autobind';
import { Form, Field } from 'react-final-form';
import { Form as SemanticUIForm, Header, Modal, Message, Divider, Button, Label, Segment, Dimmer, Loader, Icon } from 'semantic-ui-react';
import api from '../../utils/api';
import { connect } from 'react-redux';
import config from '../../config/config';
import { renderDropzoneInputField } from './utils/render-fields';
import FeaturesTable from '../features-table';
import { flatMap, trim, isNull } from 'lodash';
import ErrorBoundary from '../../utils/error-boundary';
import ReCAPTCHA from "react-google-recaptcha";

const asyncValidate = (validation_url, setValidation, captchaCode) => (values, allValues, meta) => new Promise((resolve) => {
    const progress = () => {};
    api.upload(validation_url, values[0], progress, { 'X-RecaptchaAuthorization': captchaCode }, {}, false)
        .then(data => {
            setValidation({ ...data.body });
            resolve();
        })
        .catch(error => {
            const message = (error.response.body && error.response.body.message) || error;
            setValidation({ valid: false, reason: message });
            resolve(`Invalid solution "${message}"`);
        });
});

const composeValidators = (...validators) => (value, allValues, meta) => {
    if (meta.pristine)
        return;
    return validators.reduce((error, validator) => error || validator(value), undefined);
}

const composeValidatorsWithAsync = (asyncValidator, ...validators) => (value, allValues, meta) => {
    if (meta.pristine)
        return;
    const syncError = composeValidators(...validators)(value, allValues, meta);
    return !!syncError ? syncError : asyncValidator(value, allValues, meta);
};

const required = setValidation => value => {
    if (!value) {
        const message = 'Solution file is required';
        setValidation({ valid: false, reason: message });
        return message;
    }
};

const mustHaveFormats = (formats, setValidation) => value => {
    const [, extension] = value[0].name.match(/\.([0-9a-z]+)$/i);
    if (!extension || formats.indexOf(extension) === -1) {
        const message = `Format "${extension}" not supported, try one of [${formats.join(', ')}]`;
        setValidation({ valid: false, reason: message });
        return message;
    }
};

const SolutionFileForm = connect(state => ({
    formats:  state.problem.data.formats.solution,   
    instance: state.instance.data.name,
    validation_url: state.instance.data._links.validate_solution.url || undefined
}))(props => {    
    const { formats, instance, validation_url, captchaCode } = props;
    const [validation, setValidation] = useState({});
    const cost_components = flatMap(validation.cost_components || {}, (value, key) => ({ name: key, value }));
    const totalCost = validation.cost;

    return (
        <Form onSubmit={(value) => { console.log(value); }}>
             {({ handleSubmit, submitting, values, validating, invalid }) => (
                <SemanticUIForm onSubmit={handleSubmit} widths="equal">
                    <Segment>
                        <Dimmer active={validating} inverted>
                            <Loader>Validating</Loader>
                        </Dimmer>
                        <ErrorBoundary>
                        <Field name="solutionFile" component={renderDropzoneInputField} label="File"
                                placeholder="Drop a file here, or click to select a file to upload for validation."
                                validate={composeValidatorsWithAsync(asyncValidate(validation_url, setValidation, captchaCode), required(setValidation), mustHaveFormats(formats, setValidation))}
                                formats={formats}
                                filePrefix={instance}
                                required
                        />
                        {validation.valid ? (
                                <Message success visible={validation !== {}}>
                                    <Message.Header>Validation successful</Message.Header>
                                    <div>The file is valid.</div>
                                    <div>
                                        <Divider horizontal/>
                                        <Header>Features</Header>
                                        <FeaturesTable features={cost_components} totalCost={totalCost}/>
                                    </div>
                                </Message>
                        ) : null }
                        <Message>
                            <Message.Header>Select a solution file for validation</Message.Header>
                            <div>
                                {formats && formats.length > 1 &&
                                <span>The supported file extensions/formats for this problem are: </span>}
                                {formats && formats.length === 1 &&
                                <span>The supported file extension/format for this problem is: </span>}
                                {formats.map((ext, index) => <Label key={index} color="blue">{ext}</Label>)}
                            </div>
                            <p>The solution will be validated on the server.</p>
                        </Message>
                    </ErrorBoundary>
                </Segment>
            </SemanticUIForm>
        )}
    </Form>
    );
});

class ValidateSolutionDialog extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);
        this.state = { modalOpen: false, confirmCancelOpen: false, submitConfirmation: false,
            captchaCode: null };
    }

    handleOpen(e) {
        this.setState({
            modalOpen: true
        });
    }

    handleClose(e) {
        this.setState({
            modalOpen: false,
            confirmCancelOpen: false,
            submitConfirmation: false,
            submissionError: false,
        });
    }

    handleCancel(e) {
        this.handleClose(e);
    }

    submit(values) {
        const data = {
            name: values.solutionName,
            description: values.solutionDescription || "",
            tags: values.solutionMethod
        };
        const p = api.postWithFile(this.props.instance._links.create_solution.url, data, values.solutionFile[0], 'solution');
        p.then(() => this.setState({ submitConfirmation: true })).catch((error) => this.setState({ submissionError: error.response.body.message }));
        return p;
    }
    
    recaptchaChanged(value) {
        this.setState({
            captchaCode: value
        });
    }

    validate() {

    }

    render() {
        const { modalOpen, captchaCode } = this.state;
        return (
            <Modal trigger={<Button onClick={this.handleOpen} icon="share square" content="Validate solution"/>}
                open={modalOpen}
                closeOnDimmerClick={false} closeOnEscape={true} closeIcon='close'
                onClose={this.handleClose}>
                <Modal.Header>
                    <Header as="h2">Validate solution</Header>
                </Modal.Header>
                <Modal.Content>             
                {isNull(captchaCode) &&           
                    <ReCAPTCHA sitekey={config.captchaSiteKey} onChange={this.recaptchaChanged} />
                }
                {!isNull(captchaCode) && 
                    <SolutionFileForm captchaCode={captchaCode}/>
                } 
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose}>
                        <Icon name='close' /> Close
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default ValidateSolutionDialog;