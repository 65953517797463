// FIXME: let config combine the configuration with base and the overrides taken from the current running environment

const config = {
    mountpoint: process.env.REACT_APP_API_MOUNTPOINT,
    downloadMountpoint: process.env.REACT_APP_DOWNLOAD_MOUNTPOINT,
    socketMountpoint: process.env.REACT_APP_SOCKET_MOUNTPOINT,
    keycloakMountpoint: process.env.REACT_APP_KEYCLOAK_MOUNTPOINT,
    keycloakClientSecret: process.env.REACT_APP_KEYCLOAK_SECRET,
    captchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY
};

export default config;
